import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const HomeScreen = () => {
    const navigate = useNavigate();


    return (
		<div className="container-fluid d-flex flex-column min-vh-100 bg-light">
            {/* Header */}

            {/* Main Content */}
            <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1 mainContent">
                <img className="logo" src="/images/logo-tennis-social-app.png"/>
					<div className="btnContent">
						{/* Button for Login */}
						<button
							className="btn btnColor"
							onClick={() => navigate('/login')}
						>
							Connexion salut
						</button>

						{/* Button for Register */}
						<button
							className="btn btnNoColor"
							onClick={() => navigate('/register')}
						>
							Créer mon compte
						</button>
					</div>
           	 </div>
       	 </div>
    );
};

export default HomeScreen;
