import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import BottomNavigationBar from './BottomNavigationBar';

const ProfileScreen = () => {
    const [user, setUser] = useState({
        firstName: '',
        profileImage: '/images/default-avatar.png',
        level: '',
        ranking: '',
    });
    const [isPostModalOpen, setIsPostModalOpen] = useState(false);
    const navigate = useNavigate();

    // Vérification du token et récupération des données utilisateur
    useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
        navigate('/login');
        return;
    }

    axios.get('https://api-social-club.facilidev.fr/profile', {
        headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
        setUser(response.data);
    })
    .catch(() => {
        navigate('/login');
    });
}, [navigate]);
	
    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    const handleOpenModal = () => {
        setIsPostModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsPostModalOpen(false);
    };

    return (
        <div className="container-fluid d-flex flex-column min-vh-100 bg-light">
            {/* Header */}
            <div
                className="d-flex justify-content-between align-items-center bg-light border-bottom fixed-top"
                style={{ height: '60px', padding: '0 15px' }}
            >
                {/* Flèche Retour */}
                <i
                    className="bi bi-arrow-left text-dark"
                    style={{ fontSize: '1.5rem', cursor: 'pointer' }}
                    onClick={() => window.history.back()}
                ></i>

                {/* Titre Centré */}
                <h5 className="m-0 text-dark fw-bold" style={{ fontFamily: 'Inter, sans-serif' }}>
                    SOCIAL CLUB
                </h5>

                {/* Icône Déconnexion */}
                <i
                    className="bi bi-box-arrow-right text-danger"
                    style={{ fontSize: '1.5rem', cursor: 'pointer' }}
                    onClick={handleLogout}
                ></i>
            </div>

            {/* Main Content */}
			<div className="mainContent">
            <div className="container text-center profileInfo">
                    {/* Affichage des informations utilisateur */}
                    {user && (
                        <>
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                <img
                                    src={user.profileImage || '/images/default-avatar.png'}
                                    alt="Profile"
                                    className="rounded-circle"
                                    style={{
                                        width: '80px',
                                        height: '80px',
                                        objectFit: 'cover',
                                    }}
                                />
                                {/* Icône de modification */}
                                <i
                                    className="bi bi-pencil"
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                        fontSize: '1.2rem',
                                        color: '#FFA500',
                                        cursor: 'pointer',
                                        backgroundColor: 'white',
                                        borderRadius: '50%',
                                        padding: '5px',
                                    }}
                                    onClick={() => navigate('/edit-profile')}
                                ></i>
                            </div>
                            <h4 className="fw-bold mt-3">
                                Bienvenue <span style={{ color: 'black' }}>{user.firstName}</span>
                            </h4>
                            <p className="mb-0 text-muted" style={{ fontSize: '12px' }}>
                                Niveau {user.level || 'Non défini'}
                            </p>
                            {user.isRanked === 1 && user.ranking && (
                                <p className="mb-0 text-muted" style={{ fontSize: '12px' }}>
                                    {user.ranking}
                                </p>
                            )}
                        </>
                    )}
                </div>
            {/* Cards */}
            <div className="px-3 profileContent">
                {/* Card 1 */}
                <div
                    className="card mb-3 p-3"
                    onClick={handleOpenModal}
                    style={{
                        backgroundColor: 'var(--orange-clair)',
                        borderRadius: '10px',
                        cursor: 'pointer',
                    }}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="m-0 fw-bold text-black">Organise un match</p>
                        <i className="bi bi-arrow-right text-black"></i>
                    </div>
                    <p className="text-muted mt-2" style={{ fontSize: '12px' }}>
                        Propose une date et un créneau aux autres membres du club
                    </p>
                </div>

                {/* Card 2 */}
                <Link to="/postscreen" className="text-decoration-none">
                    <div
                        className="card mb-3 p-3"
                        style={{ backgroundColor: 'var(--orange-clair)', borderRadius: '10px' }}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="m-0 fw-bold text-black">Trouve un match</p>
                            <i className="bi bi-arrow-right text-black"></i>
                        </div>
                        <p className="text-muted mt-2" style={{ fontSize: '12px' }}>
                            Explore les propositions de créneaux sur le terrain et envoie une proposition à celles qui te correspondent !
                        </p>
                    </div>
                </Link>

                {/* Card 3 */}
                <Link to="/users" className="text-decoration-none">
                    <div
                        className="card mb-3 p-3"
                        style={{ backgroundColor: 'var(--orange-clair)', borderRadius: '10px' }}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="m-0 fw-bold text-black">Trouve un partenaire</p>
                            <i className="bi bi-arrow-right text-black"></i>
                        </div>
                        <p className="text-muted mt-2" style={{ fontSize: '12px' }}>
                            Parcours la liste des membres inscrits sur la plateforme et ajoute-les en amis pour créer ton propre tournoi !
                        </p>
                    </div>
                </Link>

                {/* Card 4 */}
                <Link to="/requests" className="text-decoration-none">
                    <div
                        className="card mb-3 p-3"
                        style={{ backgroundColor: 'var(--orange-clair)', borderRadius: '10px' }}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="m-0 fw-bold text-black">Historique de mes matchs</p>
                            <i className="bi bi-arrow-right text-black"></i>
                        </div>
                        <p className="text-muted mt-2" style={{ fontSize: '12px' }}>
                            Parcours la liste de tes matchs passés et prépare de futurs affrontements !
                        </p>
                    </div>
                </Link>
            </div>
</div>

            {/* Bottom Navigation */}
            <BottomNavigationBar />

            {/* Match Modal */}
            {isPostModalOpen && (
                <MatchModal isOpen={isPostModalOpen} onClose={handleCloseModal} />
            )}
        </div>
    );
};

const MatchModal = ({ isOpen, onClose }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        category: '',
        composition: '',
        date: '',
        startTime: '',
        endTime: '',
    });

    const handleNextStep = () => {
        if (currentStep < 4) setCurrentStep(currentStep + 1);
    };

    const handlePreviousStep = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
    };

    const handleSelect = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const handleSubmit = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            alert('Vous devez être connecté pour créer un match.');
            return;
        }

        try {
            await axios.post(
    'https://api-social-club.facilidev.fr/posts',
    formData,
    { headers: { Authorization: `Bearer ${token}` } }
);

            alert('Match créé avec succès !');
            onClose();
        } catch (error) {
            alert('Erreur lors de la création du match.');
        }
    };

    if (!isOpen) return null;

    return (
         <div
            className="modal show d-block"
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: '#FFF',
                zIndex: 1050,
            }}
        >
            <div className="d-flex flex-column h-100" style={{ fontFamily: 'Inter, sans-serif' }}>
                {/* Header */}
                <div className="d-flex align-items-center justify-content-between p-3">
                    {currentStep === 1 ? (
                        <i
                            className="bi bi-x-lg"
                            style={{ fontSize: '15px', cursor: 'pointer' }}
                            onClick={onClose}
                        ></i>
                    ) : (
                        <i
                            className="bi bi-arrow-left"
                            style={{ fontSize: '15px', cursor: 'pointer' }}
                            onClick={handlePreviousStep}
                        ></i>
                    )}
                    <span style={{ fontSize: '11px' }}>{`${currentStep}/4`}</span>

                </div>

                {/* Progress Bar */}
                <div className="progress" style={{ height: '5px', margin: '0', borderRadius: '0' }}>
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                            width: `${(currentStep / 4) * 100}%`,
                            backgroundColor: 'var(--orange)',
                        }}
                    ></div>
                </div>

                {/* Body */}
                <div
                    className="flex-grow-1 d-flex flex-column align-items-center justify-content-center"
                    style={{ padding: '20px' }}
                >
                    <h5 className="text-center mb-4" style={{ fontWeight: 'bold', marginBottom: '60px' }}>
                        CRÉE TON MATCH
                    </h5>
                    {currentStep === 1 && (
                        <div className="text-center">
                            <h6 style={{ fontSize: '16px', marginBottom: '20px' }}>Quelle catégorie ?</h6>
                            <div className="d-flex justify-content-around">
                                {['Homme', 'Femme', 'Mixte'].map((category, index) => (
                                    <div
                                        key={index}
                                        className="p-3 m-2 rounded text-center"
                                        style={{
                                            backgroundColor:
                                                formData.category === category
                                                    ? 'var(--orange)'
                                                    : 'var(--orange-clair)',
                                            color:
                                                formData.category === category ? '#FFF' : '#838383',
                                            cursor: 'pointer',
                                            width: '100px',
                                        }}
                                        onClick={() => handleSelect('category', category)}
                                    >
                                        <i
                                            className={`bi ${
    category === 'Homme'
        ? 'bi-gender-male'
        : category === 'Femme'
        ? 'bi-gender-female'
        : 'bi-people'
}`}
                                        ></i>
                                        <p style={{ fontSize: '14px' }}>{category}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {currentStep === 2 && (
                        <div className="text-center">
                            <h6 style={{ fontSize: '16px', marginBottom: '20px' }}>
                                Combien de joueurs ?
                            </h6>
                            <div className="d-flex justify-content-around">
                                {['1 vs 1', '2 vs 2'].map((composition, index) => (
                                    <div
                                        key={index}
                                        className="p-3 m-2 rounded text-center"
                                        style={{
                                            backgroundColor:
                                                formData.composition === composition
                                                    ? 'var(--orange)'
                                                    : 'var(--orange-clair)',
                                            color:
                                                formData.composition === composition
                                                    ? '#FFF'
                                                    : '#838383',
                                            cursor: 'pointer',
                                            width: '100px',
                                        }}
                                        onClick={() => handleSelect('composition', composition)}
                                    >
                                        <i
                                            className={`bi ${
                                                composition === '1 vs 1'
                                                    ? 'bi-person-fill'
                                                    : 'bi-people-fill'
                                            }`}
                                        ></i>
                                        <p style={{ fontSize: '14px' }}>{composition}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {currentStep === 3 && (
    <div className="text-center">
        <h6 style={{ fontSize: '16px', marginBottom: '20px' }}>Quel jour ?</h6>
        <input
            type="date"
            className="form-control"
            value={formData.date}
            onChange={(e) => handleSelect('date', e.target.value)}
            min={new Date().toISOString().split('T')[0]} // Restreint aux jours présents et futurs
            style={{ border: '1px solid var(--orange)', padding: '10px' }}
        />
    </div>
)}
                    {currentStep === 4 && (
    <div className="text-center">
        <h6 style={{ fontSize: '16px', marginBottom: '20px' }}>Quelle heure ?</h6>
        <div className="d-flex justify-content-center">
            {/* Select pour l'heure de début */}
            <select
                className="form-select"
                value={formData.startTime}
                onChange={(e) => handleSelect('startTime', e.target.value)}
                style={{ marginRight: '10px' }}
            >
                {Array.from({ length: 13 }, (_, i) => `${9 + i}:00`).map((time) => (
                    <option key={time} value={time}>
                        {time}
                    </option>
                ))}
            </select>

            {/* Select pour l'heure de fin */}
            <select
                className="form-select"
                value={formData.endTime}
                onChange={(e) => handleSelect('endTime', e.target.value)}
            >
                {Array.from({ length: 13 }, (_, i) => `${9 + i}:00`).map((time) => (
                    <option key={time} value={time}>
                        {time}
                    </option>
                ))}
            </select>
        </div>
    </div>
)}
                </div>

                {/* Footer */}
                <div className="p-3 text-center">
                    <button
                        className="btn"
                        style={{
                            backgroundColor: 'var(--orange)',
                            color: '#FFF',
                            padding: '10px 30px',
                            borderRadius: '8px',
                        }}
                        onClick={currentStep < 4 ? handleNextStep : handleSubmit}
                    >
                        {currentStep < 4 ? 'Continuer' : 'Créer mon match'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProfileScreen;