import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import BottomNavigationBar from './BottomNavigationBar';

const RequestScreen = () => {
    const [myParticipations, setMyParticipations] = useState([]);
    const [myOrganizedRequests, setMyOrganizedRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedMatch, setSelectedMatch] = useState(null); // Match sélectionné pour afficher les demandes
    const [requests, setRequests] = useState([]); // Demandes de participation pour un match

    // Fonction pour récupérer les participations de l'utilisateur
    const fetchMyParticipations = async () => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.get(
                'https://api-social-club.facilidev.fr/requests/my-participations',
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setMyParticipations(data);
        } catch (err) {
            console.error('Erreur lors de la récupération des participations :', err);
        }
    };

    // Fonction pour récupérer les demandes pour les matchs organisés par l'utilisateur
    const fetchMyOrganizedRequests = async () => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await axios.get(
                'https://api-social-club.facilidev.fr/requests/my-organized-participations',
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setMyOrganizedRequests(data);
        } catch (err) {
            console.error('Erreur lors de la récupération des demandes organisées :', err);
        }
    };

    // Fonction pour récupérer les demandes spécifiques pour un match
    const fetchRequestsForMatch = async (postId) => {
        try {
            const token = localStorage.getItem('token');
            if (!postId) {
                console.error("Erreur : l'ID du post est manquant");
                return;
            }
            const { data } = await axios.get(
                `https://api-social-club.facilidev.fr/requests/organized/${postId}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setRequests(data);
        } catch (err) {
            console.error('Erreur lors de la récupération des demandes pour le match :', err);
        }
    };

    // Fonction pour accepter ou refuser une demande
    const handleDecision = async (participationId, status) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(
                `https://api-social-club.facilidev.fr/requests/participations/${participationId}`,
                { status },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            if (selectedMatch) {
                fetchRequestsForMatch(selectedMatch.post_id); // Rafraîchir les demandes après mise à jour
            }
        } catch (err) {
            console.error('Erreur lors de la mise à jour de la demande :', err);
        }
    };

    // Fonction pour supprimer un match
    const handleDeleteMatch = async (postId) => {
        try {
            const token = localStorage.getItem('token');
            if (!postId) {
                console.error("Erreur : l'ID du post est manquant");
                return;
            }
            await axios.delete(
                `https://api-social-club.facilidev.fr/posts/${postId}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            fetchMyOrganizedRequests(); // Rafraîchir les matchs organisés
        } catch (err) {
            console.error('Erreur lors de la suppression du match :', err);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await fetchMyParticipations();
            await fetchMyOrganizedRequests();
            setIsLoading(false);
        };

        fetchData();
    }, []);

    if (isLoading) return <p>Chargement...</p>;

    return (
        <div>
            {/* Header */}
            <div
                className="d-flex justify-content-between align-items-center bg-light border-bottom fixed-top"
                style={{ height: '60px', padding: '0 15px' }}
            >
                <i
                    className="bi bi-arrow-left text-dark"
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                    onClick={() => window.history.back()}
                ></i>
                <h5 className="m-0 text-dark fw-bold">Mes matchs</h5>
                <i
                    className="bi bi-funnel text-dark"
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                ></i>
            </div>

            <div className="container-fluid px-3 mainContent">
                {/* Mes participations */}
                <h3 className="mb-3">Mes Participations</h3>
{myParticipations.length === 0 ? (
    <p className="text-muted">Aucune participation.</p>
) : (
    myParticipations.map((participation) => (
        <div
            key={participation.id}
            className="d-flex align-items-center card my-2 shadow-sm"
            style={{
                backgroundColor: '#f7f1e3',
                borderRadius: '10px',
                padding: '15px',
                columnGap: '15px',
            }}
        >
            {/* Image de profil */}
            <img
                src={participation.organizer_profileImage || '/images/default-avatar.png'}
                alt="Profile"
                style={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                }}
            />

            {/* Informations de l'utilisateur et du match */}
            <div className="flex-grow-1">
                <h5 className="mb-1">{`${participation.organizer_firstName} ${participation.organizer_lastName}`}</h5>
                <p className="mb-1" style={{ fontSize: '0.9rem', color: '#7f8c8d' }}>
                    Niveau : {participation.level || 'N/A'} | Classement : {participation.ranking || 'N/A'}
                </p>
                <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
                    <span>
                        <i className="bi bi-gender-ambiguous"></i> {participation.gender || 'N/A'}
                    </span>
                    <span>
                        <i className="bi bi-people"></i> {participation.category || 'N/A'}
                    </span>
                    <span>
                        <i className="bi bi-calendar"></i> {new Date(participation.date).toLocaleDateString()}
                    </span>
                    <span>
                        <i className="bi bi-clock"></i> {participation.startTime} - {participation.endTime}
                    </span>
                </div>
            </div>

            {/* Statut */}
            <div>
                <span
                    className={`badge ${
                        participation.status === 'pending'
                            ? 'bg-warning'
                            : participation.status === 'accepted'
                            ? 'bg-success'
                            : 'bg-danger'
                    }`}
                >
                    {participation.status === 'pending'
                        ? 'En attente de validation'
                        : participation.status === 'accepted'
                        ? 'Accepté'
                        : 'Refusé'}
                </span>
            </div>
        </div>
    ))
)}

                {/* Mes matches organisés */}
                <h3 className="mt-4 mb-3">Mes Matches Organisés</h3>
                {myOrganizedRequests.length === 0 ? (
                    <p className="text-muted">Aucune demande reçue.</p>
                ) : (
                    myOrganizedRequests.map((request) => (
                        <div key={request.post_id} className="card my-2 shadow-sm">
                            <div className="card-body">
                                <h5>{request.category}</h5>
                                <p>Date : {new Date(request.date).toLocaleDateString()}</p>
                                <p>Heure : {request.startTime} - {request.endTime}</p>
                                <div className="d-flex justify-content-between">
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => handleDeleteMatch(request.post_id)}
                                    >
                                        Supprimer
                                    </button>
                                    <button
                                        className="btn btn-primary btn-sm"
                                        onClick={() => {
                                            setSelectedMatch(request);
                                            fetchRequestsForMatch(request.post_id);
                                        }}
                                    >
                                        Voir demandes
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                )}

                {/* Popup */}
                {selectedMatch && (
                    <div
                        className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', zIndex: 1050 }}
                    >
                        <div className="bg-white rounded p-4 shadow-lg position-relative">
                            <button
                                className="btn-close position-absolute top-0 end-0"
                                onClick={() => setSelectedMatch(null)}
                            ></button>
                            <h5 className="mb-3">Demandes pour le match</h5>
                            {requests.length === 0 ? (
                                <p className="text-muted">Aucune demande pour ce match.</p>
                            ) : (
                                requests.map((request) => (
                                    <div key={request.id}>
                                        <p>{request.firstName} {request.lastName}</p>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                )}
            </div>

            <BottomNavigationBar />
        </div>
    );
};

export default RequestScreen;
