import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import BottomNavigationBar from './BottomNavigationBar';

const PostScreen = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedPost, setSelectedPost] = useState(null);
    const currentUserId = parseInt(localStorage.getItem('user_id')); // ID utilisateur connecté
    const [feedback, setFeedback] = useState('');
    const [participationPostIds, setParticipationPostIds] = useState([]); // Liste des post_id associés aux participations

    // Récupérer les posts
    const fetchPosts = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('Token manquant, veuillez vous reconnecter.');
            setLoading(false);
            return;
        }

        try {
            console.log('Fetching posts...');
            const response = await axios.get('https://api-social-club.facilidev.fr/posts', {
                headers: { Authorization: `Bearer ${token}` },
            });
            console.log('Posts fetched:', response.data);
            setPosts(response.data);
        } catch (err) {
            setError(err.response?.data?.error || 'Erreur lors du chargement des posts.');
            console.error('Error fetching posts:', err);
        } finally {
            setLoading(false);
        }
    };

    // Récupérer les participations et leurs post_id
    const fetchParticipations = async () => {
        const token = localStorage.getItem('token');
        try {
            console.log('Fetching participations...');
            const response = await axios.get(
                'https://api-social-club.facilidev.fr/requests/my-participations',
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            console.log('Participations fetched:', response.data);
            const postIds = response.data.map((participation) => participation.post_id);
            console.log('Extracted post IDs from participations:', postIds);
            setParticipationPostIds(postIds);
        } catch (err) {
            console.error('Erreur lors de la récupération des participations :', err);
        }
    };

    useEffect(() => {
        console.log('Fetching data...');
		console.log('Fetching data for user:', currentUserId); // Affiche l'ID utilisateur connecté
        fetchPosts();
        fetchParticipations();
    }, []);

    const openPopup = (post) => {
        console.log('Opening popup for post:', post);
        setSelectedPost(post);
    };

    const closePopup = () => {
        console.log('Closing popup...');
        setSelectedPost(null);
    };

    const handleParticipationRequest = async (postId) => {
        const token = localStorage.getItem('token');
        try {
            console.log('Sending participation request for post ID:', postId);
            await axios.post(
                'https://api-social-club.facilidev.fr/requests',
                { post_id: postId },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setFeedback('Votre demande de participation a été envoyée avec succès.');
            console.log('Participation request successful for post ID:', postId);

            // Ajouter le post_id à la liste des participations pour désactiver le bouton
            setParticipationPostIds((prev) => [...prev, postId]);
        } catch (err) {
            setFeedback('Une erreur est survenue lors de l’envoi de la demande.');
            console.error('Erreur lors de la demande de participation:', err);
        } finally {
            setTimeout(() => setFeedback(''), 3000);
            closePopup();
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    const formatTime = (timeString) => {
        if (!timeString) return '';
        const [hours, minutes] = timeString.split(':');
        return `${hours}h${minutes}`;
    };

    return (
        <div className="container-fluid d-flex flex-column min-vh-100 bg-light">
            <div
                className="d-flex justify-content-between align-items-center bg-light border-bottom fixed-top"
                style={{ height: '60px', padding: '0 15px' }}
            >
                <i
                    className="bi bi-arrow-left text-dark"
                    style={{ fontSize: '1.5rem', cursor: 'pointer' }}
                    onClick={() => window.history.back()}
                ></i>
                <h5 className="m-0 text-dark fw-bold" style={{ fontFamily: 'Inter, sans-serif' }}>
                    Trouve ton Match
                </h5>
                <i className="bi bi-search" style={{ fontSize: '1.5rem', cursor: 'pointer' }}></i>
            </div>

            <div className="flex-grow-1 px-3 mainContent">
                {feedback && <p className="text-center text-success">{feedback}</p>}

                {loading && (
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                    </div>
                )}

                {error && <p className="text-danger text-center">{error}</p>}

                {!loading && !error && posts.length === 0 && (
                    <p className="text-center text-muted">Aucun match disponible.</p>
                )}

                {!loading &&
    posts.map((post, index) => {
        // La carte est désactivée si l'utilisateur a participé ou s'il est l'organisateur
        const isDisabled = post.hasParticipated || post.userId === currentUserId;

        return (
            <div
                key={index}
                className={`card mb-3 shadow-sm ${isDisabled ? 'grayed-card' : ''}`}
                style={{
                    backgroundColor: isDisabled ? '#e9ecef' : 'var(--orange-clair)',
                    borderRadius: '10px',
                }}
            >
                <div className="card-body d-flex align-items-center">
                    <img
                        src={post.profileImage || '/images/default-avatar.png'}
                        alt="User Avatar"
                        className="rounded-circle me-3"
                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                    />
                    <div className="flex-grow-1">
                        <h5
                            className="fw-bold mb-1"
                            style={{ color: '#000', fontSize: '12px' }}
                        >
                            {post.firstName} {post.lastName}
                        </h5>
                        <p className="mb-0" style={{ fontSize: '10px' }}>
                            Niveau : {post.level} • Classement : {post.ranking || 'N/A'}
                        </p>
                        <p className="mb-0" style={{ fontSize: '10px' }}>
                            {post.category} • {post.composition}
                        </p>
                        <p className="mb-0" style={{ fontSize: '10px' }}>
                            {formatDate(post.date)} • {formatTime(post.startTime)} -{' '}
                            {formatTime(post.endTime)}
                        </p>
                    </div>
                    {!isDisabled && (
                        <button
                            className="btn btn-sm"
                            style={{
                                backgroundColor: 'var(--noir)',
                                color: '#fff',
                                fontSize: '12px',
                            }}
                            onClick={() => openPopup(post)}
                        >
                            Jouer <i className="bi bi-arrow-right"></i>
                        </button>
                    )}
                </div>
            </div>
        );
    })}
            </div>

            <BottomNavigationBar />

            {selectedPost && (
                <div
                    className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', zIndex: 1050 }}
                >
                    <div
                        className="bg-white rounded p-4 shadow-lg position-relative"
                        style={{ maxWidth: '400px', width: '90%' }}
                    >
                        <button
                            className="btn position-absolute top-0 end-0 m-2"
                            onClick={closePopup}
                            style={{
                                fontSize: '1.2rem',
                                color: '#000',
                                background: 'none',
                                border: 'none',
                            }}
                        >
                            <i className="bi bi-x"></i>
                        </button>
                        <div className="text-center mb-4">
                            <img
                                src={selectedPost.profileImage || '/images/default-avatar.png'}
                                alt="User Avatar"
                                className="rounded-circle"
                                style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                            />
                            <h5 className="fw-bold mt-2">
                                {selectedPost.firstName} {selectedPost.lastName}
                            </h5>
                            <p className="mb-0">Niveau : {selectedPost.level}</p>
                            <p className="mb-0">Classe : {selectedPost.ranking}</p>
                        </div>
                        <p className="mb-1">
                            <i className="bi bi-gender-male"></i> {selectedPost.category}
                        </p>
                        <p className="mb-1">
                            <i className="bi bi-people-fill"></i> {selectedPost.composition}
                        </p>
                        <p className="mb-1">
                            <i className="bi bi-calendar-event"></i> {formatDate(selectedPost.date)}
                        </p>
                        <p className="mb-1">
                            <i className="bi bi-clock"></i> {formatTime(selectedPost.startTime)} -{' '}
                            {formatTime(selectedPost.endTime)}
                        </p>
                        <button
                            className="btn w-100 mt-3"
                            style={{
                                backgroundColor: 'var(--orange)',
                                color: '#FFF',
                                fontWeight: '600',
                            }}
                            onClick={() => handleParticipationRequest(selectedPost.id)}
                        >
                            Faire une demande de participation
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PostScreen;
