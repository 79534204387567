import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BottomNavigationBar from './BottomNavigationBar';

const MessageList = () => {
    const [conversations, setConversations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        axios
            .get('https://api-social-club.facilidev.fr/messages/conversations', {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setConversations(response.data);
            })
            .catch((err) => {
                setError(err.response?.data?.error || 'Une erreur est survenue.');
            })
            .finally(() => setIsLoading(false));
    }, [navigate]);

    // Fonction pour tronquer le texte
    const truncateMessage = (message, maxLength = 50) => {
        if (!message) return 'Commencez une conversation';
        return message.length > maxLength ? message.slice(0, maxLength) + '...' : message;
    };

    return (
        <div className="container-fluid d-flex flex-column min-vh-100 bg-light">
            {/* Header */}
            <div
                className="d-flex justify-content-between align-items-center bg-light border-bottom fixed-top"
                style={{ height: '60px', padding: '0 15px' }}
            >
                <i
                    className="bi bi-arrow-left text-dark"
                    style={{ fontSize: '1.5rem', cursor: 'pointer' }}
                    onClick={() => window.history.back()}
                ></i>
                <h5 className="m-0 text-dark fw-bold" style={{ fontFamily: 'Inter, sans-serif' }}>
                    La Messagerie
                </h5>
                <i
                    className="bi bi-person-plus-fill text-dark"
                    style={{ fontSize: '1.5rem', cursor: 'pointer' }}
                ></i>
            </div>

            <div
                className="flex-grow-1 px-3 Content"
                style={{ overflowY: 'auto' }}
            >
                {error && (
                    <div className="alert alert-danger text-center" role="alert">
                        {error}
                    </div>
                )}
                {isLoading ? (
                    <div className="text-center mt-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                    </div>
                ) : conversations.length > 0 ? (
                    conversations.map((user) => (
                        <div
                            key={user.id}
                            className="card mb-3 p-2 shadow-sm"
                            style={{
                                backgroundColor: 'var(--orange-clair)',
                                borderRadius: '10px',
                                cursor: 'pointer',
                            }}
                            onClick={() => navigate(`/messages/conversation/${user.id}`)}
                        >
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <img
                                        src={user.profileImage || '/images/default-avatar.png'}
                                        alt="profile"
                                        className="rounded-circle me-3"
                                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                    />
                                    <div>
                                        <h6 className="fw-bold mb-0" style={{ fontSize: '16px' }}>
                                            {user.firstName} {user.lastName}
                                        </h6>
                                        <p
                                            className="text-muted mb-0"
                                            style={{
                                                fontSize: '12px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                maxWidth: '200px', // Vous pouvez ajuster la largeur maximale
                                            }}
                                        >
                                            {truncateMessage(user.lastMessage)}
                                        </p>
                                    </div>
                                </div>
                                <i className="bi bi-chevron-right text-dark" style={{ fontSize: '1.2rem' }}></i>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-center text-muted mt-5">Aucune conversation disponible.</p>
                )}
            </div>

            <BottomNavigationBar />
        </div>
    );
};

export default MessageList;
