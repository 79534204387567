import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import moment from "moment";
import BottomNavigationBar from "./BottomNavigationBar";

// Initialize Socket.IO
const socket = io("https://api-social-club.facilidev.fr", {
  transports: ["websocket"],
});

const Chat = () => {
  const { receiver_id } = useParams();
  const [messages, setMessages] = useState([]);
  const [content, setContent] = useState("");
  const [receiverInfo, setReceiverInfo] = useState({});
  const chatContainerRef = useRef(null);

  const currentUser = localStorage.getItem("user_id"); // Get current user ID

  const formatDate = (date) => moment(date).format("DD/MM/YYYY");
  const formatTime = (date) => moment(date).format("HH:mm");

  // Scroll to the bottom of the chat
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  // Fetch messages
  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://api-social-club.facilidev.fr/messages/conversation/${receiver_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();
      setMessages(data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  // Fetch receiver information
  const fetchReceiverInfo = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `https://api-social-club.facilidev.fr/users/${receiver_id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();
      setReceiverInfo(data);
    } catch (error) {
      console.error("Error fetching receiver info:", error);
    }
  };

  // Load data and configure WebSocket
  useEffect(() => {
    const fetchData = async () => {
      await fetchMessages();
      await fetchReceiverInfo();
      scrollToBottom(); // Scroll down after messages are fetched
    };

    fetchData();

    socket.emit("joinRoom", { sender_id: currentUser, receiver_id });

    const handleNewMessage = (message) => {
      setMessages((prev) => [...prev, message]);
    };

    socket.on("receiveMessage", handleNewMessage);

    return () => {
      socket.off("receiveMessage", handleNewMessage);
    };
  }, [receiver_id, currentUser]);

  // Scroll to bottom when messages are updated
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Send a message
  const sendMessage = () => {
    if (content.trim()) {
      const messageData = {
        sender_id: currentUser,
        receiver_id,
        content,
      };
      socket.emit("sendMessage", messageData);
      setContent("");
    }
  };

  return (
    <div className="contentMessage">
      {/* Chat Header */}
      <div className="chat-header">
        <i className="bi bi-arrow-left" onClick={() => window.history.back()}></i>
        <h5>{receiverInfo.firstName} {receiverInfo.lastName}</h5>
        <img
          src={receiverInfo.profileImage || "/images/default-avatar.png"}
          alt="Profile"
          className="chat-avatar"
        />
      </div>

      {/* Chat Messages */}
      <div ref={chatContainerRef} className="chat-messages">
        {messages.map((msg, index) => {
          const isSentByCurrentUser = String(msg.sender_id) === String(currentUser);
          const showDate =
            index === 0 ||
            (index > 0 &&
              formatDate(messages[index - 1].created_at) !== formatDate(msg.created_at));

          return (
            <React.Fragment key={index}>
              {showDate && <div className="chat-date">{formatDate(msg.created_at)}</div>}
              <div className={`chat-message ${isSentByCurrentUser ? "sent" : "received"}`}>
                {msg.content}
                <div className="chat-time">{formatTime(msg.created_at)}</div>
              </div>
            </React.Fragment>
          );
        })}
      </div>

      {/* Chat Input */}
      <div className="chat-input">
        <input
          type="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Écrire un message..."
        />
        <button onClick={sendMessage}>
          <i className="bi bi-send-fill"></i>
        </button>
      </div>

      <BottomNavigationBar />
    </div>
  );
};

export default Chat;
