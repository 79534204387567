import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BottomNavigationBar from './BottomNavigationBar';

const UserScreen = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]); // Utilisateurs filtrés
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showFilterPopup, setShowFilterPopup] = useState(false); // Affiche le popup de filtrage
    const [selectedLevels, setSelectedLevels] = useState([]); // Niveaux sélectionnés
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setError('Vous devez être connecté pour accéder à cette page.');
                setLoading(false);
                return;
            }

            try {
                const response = await axios.get('https://api-social-club.facilidev.fr/users', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUsers(response.data);
                setFilteredUsers(response.data); // Initialisation des utilisateurs filtrés
                setError('');
            } catch (err) {
                console.error('Erreur lors de la récupération des utilisateurs:', err);
                setError('Erreur lors de la récupération des membres.');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const startConversation = (userId) => {
        if (!userId) {
            console.error('ID utilisateur manquant pour démarrer une conversation.');
            return;
        }
        navigate(`/messages/conversation/${userId}`);
    };

    const addFriend = (userId) => {
        console.log(`Ajout de l'utilisateur ${userId} en ami`);
    };

    const toggleFilterPopup = () => {
        setShowFilterPopup(!showFilterPopup);
    };

    const toggleLevelSelection = (level) => {
        setSelectedLevels((prev) =>
            prev.includes(level) ? prev.filter((l) => l !== level) : [...prev, level]
        );
    };

    const applyFilters = () => {
        if (selectedLevels.length === 0) {
            setFilteredUsers(users);
        } else {
            setFilteredUsers(users.filter((user) => {
                const userLevel = parseInt(user.level); // Assurez-vous de comparer avec un nombre
                return selectedLevels.includes(userLevel);
            }));
        }
        setShowFilterPopup(false);
    };

    return (
        <div className="container-fluid d-flex flex-column min-vh-100 bg-light">
            {/* Header */}
            <div
                className="d-flex justify-content-between align-items-center bg-light border-bottom fixed-top"
                style={{ height: '60px', padding: '0 15px' }}
            >
                <i
                    className="bi bi-arrow-left text-dark"
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                    onClick={() => window.history.back()}
                ></i>

                <h5 className="m-0 text-dark fw-bold" style={{ fontFamily: 'Inter, sans-serif' }}>
                    Les Membres du club
                </h5>

                <i
                    className="bi bi-funnel text-dark"
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                    onClick={toggleFilterPopup}
                ></i>
            </div>

            {/* Main Content */}
            <div className="flex-grow-1 px-3 mainContent">
                {loading && (
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Chargement...</span>
                        </div>
                    </div>
                )}

                {error && <p className="text-danger text-center">{error}</p>}

                {!loading && filteredUsers.length > 0 && (
                    <div>
                        {filteredUsers.map((user, index) => (
                            <div
                                key={index}
                                className="card mb-3 p-2 shadow-sm"
                                style={{
                                    backgroundColor: 'var(--orange-clair)',
                                    borderRadius: '10px',
                                    position: 'relative',
                                }}
                            >
                                <div className="d-flex align-items-center">
                                    <img
                                        src={user.profileImage || '/images/default-avatar.png'}
                                        alt="User Avatar"
                                        className="rounded-circle me-3"
                                        style={{
                                            width: '60px',
                                            height: '60px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                    <div className="flex-grow-1">
                                        <h6
                                            className="fw-bold mb-1"
                                            style={{ color: '#000', fontSize: '16px' }}
                                        >
                                            {user.firstName || 'Prénom'} {user.lastName || 'Nom'}
                                        </h6>
                                        <p className="mb-0 text-muted" style={{ fontSize: '12px' }}>
                                            Niveau : {user.level || 'Non défini'}
                                        </p>
                                        {user.isRanked === 1 && user.ranking && (
                                            <p className="mb-0 text-muted" style={{ fontSize: '12px' }}>
                                                Classement : {user.ranking}
                                            </p>
                                        )}
                                    </div>
                                    <div className="d-flex">
                                        <i
                                            className="bi bi-chat me-3 text-dark"
                                            style={{ fontSize: '1.5rem', cursor: 'pointer' }}
                                            title="Envoyer un message"
                                            onClick={() => startConversation(user.id)}
                                        ></i>
                                        <i
                                            className="bi bi-person-plus-fill text-dark"
                                            style={{ fontSize: '1.5rem', cursor: 'pointer' }}
                                            title="Ajouter en ami"
                                            onClick={() => addFriend(user.id)}
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {!loading && filteredUsers.length === 0 && (
                    <p className="text-center text-muted">Aucun membre trouvé.</p>
                )}
            </div>

            <BottomNavigationBar />

            {/* Popup */}
            {showFilterPopup && (
                <div
                    className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1050 }}
                >
                    <div className="bg-white rounded p-4 shadow-lg position-relative" style={{ width: '90%', maxWidth: '400px' }}>
                        <button
                            className="btn-close position-absolute top-0 end-0"
                            onClick={toggleFilterPopup}
                        ></button>
                        <h5 className="text-center mb-2">Filtre par niveau</h5>
                        <p className="text-center mb-4 text-muted">Sélectionne un ou plusieurs niveaux</p>
                        <div className="d-flex flex-wrap justify-content-center filtres">
                            {[1, 2, 3, 4, 5, 6].map((level) => (
                                <button
                                    key={level}
                                    className={`btn m-1 ${
                                        selectedLevels.includes(level)
                                            ? 'btn-orange'
                                            : 'btn-light'
                                    }`}
                                    style={{
                                        backgroundColor: selectedLevels.includes(level)
                                            ? '#FFA500'
                                            : '#FFDAB9',
                                        color: selectedLevels.includes(level) ? '#fff' : '#000',
                                    }}
                                    onClick={() => toggleLevelSelection(level)}
                                >
                                    Niveau {level}
                                </button>
                            ))}
                        </div>
                        <button
                            className="btn btn-success w-100 mt-3"
                            onClick={applyFilters}
                        >
                            Appliquer les filtres
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserScreen;
