import React from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons

const BottomNavigationBar = () => {
    return (
        <div
            className="d-flex justify-content-around align-items-center fixed-bottom"
            style={{
                height: '60px',
                backgroundColor: 'var(--orange)', // Barre en couleur --orange
            }}
        >
            <NavLink
                to="/messages"
                className={({ isActive }) =>
                    `text-decoration-none text-center ${
                        isActive ? 'text-black' : 'text-white'
                    }`
                }
            >
                <i className="bi bi-envelope" style={{ fontSize: '1.5rem' }}></i>
                <p style={{ fontSize: '0.8rem', margin: 0 }}></p>
            </NavLink>

            <NavLink
                to="/postscreen"
                className={({ isActive }) =>
                    `text-decoration-none text-center ${
                        isActive ? 'text-black' : 'text-white'
                    }`
                }
            >
                <i className="bi bi-house" style={{ fontSize: '1.5rem' }}></i>
                <p style={{ fontSize: '0.8rem', margin: 0 }}></p>
            </NavLink>

            <NavLink
    to="/requests"
    className={({ isActive }) =>
        `text-decoration-none text-center ${
            isActive ? 'text-black' : 'text-white'
        }`
    }
>
     <i className="bi bi-calendar-check" style={{ fontSize: '1.5rem' }}></i>
                <p style={{ fontSize: '0.8rem', margin: 0 }}></p>
</NavLink>


            <NavLink
                to="/profile"
                className={({ isActive }) =>
                    `text-decoration-none text-center ${
                        isActive ? 'text-black' : 'text-white'
                    }`
                }
            >
                <i className="bi bi-person-circle" style={{ fontSize: '1.5rem' }}></i>
                <p style={{ fontSize: '0.8rem', margin: 0 }}></p>
            </NavLink>
        </div>
    );
};

export default BottomNavigationBar;
