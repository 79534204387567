import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeScreen from './components/HomeScreen';
import LoginScreen from './components/LoginScreen';
import RegisterScreen from './components/RegisterScreen';
import ProfileScreen from './components/ProfileScreen';
import PostScreen from './components/PostScreen';
import UserScreen from './components/UserScreen';
import MessageList from './components/MessageList';
import Chat from './components/Chat';
import RequestScreen from './components/RequestScreen';
import NotFound from './components/NotFound'; // Importez la page NotFound

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/login" element={<LoginScreen />} />
                <Route path="/register" element={<RegisterScreen />} />
                <Route path="/profile" element={<ProfileScreen />} />
				<Route path="/postscreen" element={<PostScreen />} />
				<Route path="/users" element={<UserScreen />} />
				<Route path="/messages" element={<MessageList />} />
				<Route path="/requests" element={<RequestScreen />} />
                <Route path="/messages/conversation/:receiver_id" element={<Chat />} />
                <Route path="*" element={<NotFound />} /> {/* Route "Not Found" */}
            </Routes>
        </Router>
    );
};

export default App;
