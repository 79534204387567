import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

const RegisterScreen = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        profileImage: null,
        level: '',
        isRanked: null,
        ranking: '',
    });
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) handleChange('profileImage', file);
    };

    const isNextDisabled = () => {
        if (currentStep === 1) return !(formData.email && formData.password);
        if (currentStep === 2)
            return !(formData.firstName && formData.lastName && formData.profileImage);
        if (currentStep === 3) return !formData.level;
        if (currentStep === 4) return formData.isRanked && !formData.ranking;
        return false;
    };

    const handleRegister = async () => {
        setIsLoading(true);
        try {
            const form = new FormData();
            Object.keys(formData).forEach((key) => {
                form.append(key, formData[key]);
            });

            const response = await axios.post(
                'https://api-social-club.facilidev.fr/auth/register',
                form,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            const token = response.data.token;
            localStorage.setItem('token', token);
            navigate('/profile');
        } catch (err) {
            setMessage(err.response?.data?.error || 'Une erreur est survenue.');
        } finally {
            setIsLoading(false);
        }
    };

    const nextStep = () => setCurrentStep((prev) => prev + 1);
    const prevStep = () => setCurrentStep((prev) => prev - 1);

    return (
        <div className="container-fluid d-flex flex-column min-vh-100 bg-light position-relative">
            {/* Header */}
            <div className="d-flex justify-content-between align-items-center px-3 py-2">
                {currentStep === 1 ? (
                    <i
                        className="bi bi-x text-dark fs-4"
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/')}
                    ></i>
                ) : (
                    <i
                        className="bi bi-arrow-left text-dark fs-4"
                        style={{ cursor: 'pointer' }}
                        onClick={prevStep}
                    ></i>
                )}
                <div className="progress w-75" style={{ height: '5px' }}>
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                            width: `${(currentStep / 4) * 100}%`,
                            backgroundColor: 'var(--orange)',
                        }}
                    ></div>
                </div>
                <span>{`${currentStep}/4`}</span>
            </div>

            {/* Étapes */}
            <div className="flex-grow-1 d-flex flex-column justify-content-center px-4">
                {/* Étape 1 */}
                {currentStep === 1 && (
                    <>
                        <h6 className="text-center mb-4">INSCRIPTION</h6>
                        <input
                            type="email"
                            className="form-control mb-3"
                            placeholder="Ton adresse email"
                            value={formData.email}
                            onChange={(e) => handleChange('email', e.target.value)}
                        />
                        <input
                            type="password"
                            className="form-control mb-3"
                            placeholder="Ton mot de passe"
                            value={formData.password}
                            onChange={(e) => handleChange('password', e.target.value)}
                        />
                    </>
                )}

                {/* Étape 2 */}
                {currentStep === 2 && (
                    <>
                        <h6 className="text-center mb-4">INSCRIPTION</h6>
                        <input
                            type="text"
                            className="form-control mb-3"
                            placeholder="Quel est ton prénom ?"
                            value={formData.firstName}
                            onChange={(e) => handleChange('firstName', e.target.value)}
                        />
                        <input
                            type="text"
                            className="form-control mb-3"
                            placeholder="Quel est ton nom ?"
                            value={formData.lastName}
                            onChange={(e) => handleChange('lastName', e.target.value)}
                        />
                        <input
                            type="file"
                            className="form-control"
                            onChange={handleImageUpload}
                        />
                    </>
                )}

                {/* Étape 3 */}
                {currentStep === 3 && (
                    <>
                        <h6 className="text-center mb-4">Quel est ton niveau ?</h6>
                        <div className="d-grid" style={{ gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
                            {['1', '2', '3', '4', '5', '6'].map((level) => (
                                <button
                                    key={level}
                                    className={`btn py-3`}
                                    style={{
                                        backgroundColor:
                                            formData.level === level
                                                ? 'var(--orange)'
                                                : 'var(--orange-clair)',
                                        color: formData.level === level ? '#fff' : '#000',
                                        border: 'none',
                                    }}
                                    onClick={() => handleChange('level', level)}
                                >
                                    Niveau {level}
                                </button>
                            ))}
                        </div>
                    </>
                )}

                {/* Étape 4 */}
                {currentStep === 4 && (
                    <>
                        <h6 className="text-center mb-4">Es-tu classé ?</h6>
                        <div className="d-grid" style={{ gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
                            <button
                                className="btn py-3"
                                style={{
                                    backgroundColor:
                                        formData.isRanked === true
                                            ? 'var(--orange)'
                                            : 'var(--orange-clair)',
                                    color: formData.isRanked === true ? '#fff' : '#000',
                                    border: 'none',
                                }}
                                onClick={() => handleChange('isRanked', true)}
                            >
                                Oui
                            </button>
                            <button
                                className="btn py-3"
                                style={{
                                    backgroundColor:
                                        formData.isRanked === false
                                            ? 'var(--orange)'
                                            : 'var(--orange-clair)',
                                    color: formData.isRanked === false ? '#fff' : '#000',
                                    border: 'none',
                                }}
                                onClick={() => handleChange('isRanked', false)}
                            >
                                Non
                            </button>
                        </div>
                        {formData.isRanked && (
                            <input
                                type="text"
                                className="form-control mt-3"
                                placeholder="Précise ton classement (ex: 15.4)"
                                value={formData.ranking}
                                onChange={(e) => handleChange('ranking', e.target.value)}
                            />
                        )}
                    </>
                )}
            </div>

            {/* Bouton en bas */}
            <div
                className="text-center"
                style={{
                    position: 'absolute',
                    bottom: '20px',
                    left: '0',
                    right: '0',
                }}
            >
                <button
                    className="btn px-5 py-2 text-white"
                    style={{
                        backgroundColor: 'var(--orange)',
                    }}
                    onClick={currentStep === 4 ? handleRegister : nextStep}
                    disabled={isNextDisabled()}
                >
                    {currentStep === 4 ? 'Terminer' : 'Continuer'}
                </button>
            </div>
        </div>
    );
};

export default RegisterScreen;
