import React, { useState } from 'react';
import axios from 'axios';

const LoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://api-social-club.facilidev.fr/auth/login', {
                email,
                password,
            });

            console.log('Response from backend:', response.data);

            // Stocker le token et l'utilisateur dans le localStorage
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user_id', response.data.user.id);

            // Rediriger l'utilisateur (ajustez selon votre logique de navigation)
            window.location.href = '/messages';
        } catch (err) {
            console.error('Error during login:', err);
            setError('Identifiants incorrects. Veuillez réessayer.');
        }
    };

    return (
        <div
		className="loginScreen"
		>
            <img className="logo" src="/images/logo-tennis-social-app.png"/>
            {error && <p style={{ color: 'red', marginBottom: '10px' }}>{error}</p>}
            <form
                onSubmit={handleLogin}
				className="loginForm"
            >
                <input
                    type="email"
                    placeholder="login"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button
                    type="submit"
                >
                    Connexion
                </button>
            </form>
        </div>
    );
};

export default LoginScreen;
